import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  /*Servicio para los eventos de busquedas y estadisticas*/
  statisticsData = new Subject<any>();


  public statisticsData$ = this.statisticsData.asObservable();

  constructor() { }

  sendDataFromStatistics(value){
    return this.statisticsData.next(value);
  }
}